import { StyleDeclarationValue, css } from 'aphrodite';
import outy, { IOutyHandle } from 'outy';
import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { styleSheet } from './styles';

export interface IDropdownProps {
	anchor: React.ReactElement<any>;
	anchorClassName?: string;
	children?: React.ReactChild;
	className?: string;
	contentClassName?: string;
	contentInnerClassName?: string;
	contentInnerStyle?: StyleDeclarationValue[] | StyleDeclarationValue;
	/** Default is "left" */
	contentPositionX?: 'left' | 'right' | 'center';
	/** Default is "bottom" */
	contentPositionY?: 'bottom' | 'top';
	disabled?: boolean;
	isOpen?: boolean;
	onOpenChanged?(isOpen: boolean): void;
	openOnClick?: boolean;
	openOnHover?: boolean;
	openOnHoverDelay?: number;
	styles?: StyleDeclarationValue[];
}

interface IState {
	anchorContentRect?: ContentRect;
	contentRect?: ContentRect;
	contentHeight?: number;
	contentScrollbarWidth?: number;
	disabled?: boolean;
	isOpen?: boolean;
}

export class Dropdown extends React.PureComponent<IDropdownProps, IState> {
	private componentRootElement: HTMLDivElement;

	private componentContentRootElement: HTMLDivElement = null;

	private outsideClickHandle: IOutyHandle;
	private hoverTimeoutHandle: any;
	public static defaultProps: Partial<IDropdownProps> = {
		contentPositionX: 'left',
		contentPositionY: 'bottom',
	};
	public state: IState = {
		contentHeight: 0,
		contentScrollbarWidth: 0,
		disabled: this.props.disabled,
		isOpen: this.props.isOpen,
	};

	public componentWillUnmount() {
		this.unRegisterClickOutside();
		this.clearOnHoverTimeout();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IDropdownProps) {
		const nextState: IState = {};

		if (this.state.isOpen !== nextProps.isOpen) {
			nextState.isOpen = nextProps.isOpen;
		}

		if (this.state.disabled !== nextProps.disabled) {
			nextState.disabled = nextProps.disabled;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const {
			contentInnerClassName,
			contentInnerStyle,
			contentPositionX,
			contentPositionY,
			className,
			openOnHover,
			anchorClassName,
			openOnClick,
			anchor,
			contentClassName,
			children,
			styles,
		} = this.props;
		const { anchorContentRect, isOpen, disabled } = this.state;
		const anchorHeight =
			anchorContentRect && !!anchorContentRect.client && !!anchorContentRect.client.height
				? anchorContentRect.client.height
				: 0;
		const contentStyle: React.CSSProperties = {};
		const menuStyles = Array.isArray(contentInnerStyle) ? contentInnerStyle : [contentInnerStyle];

		// set content anchor position x
		if (!!contentPositionX && contentPositionX === 'right') {
			contentStyle.right = 0;
		} else if (!!contentPositionX && contentPositionX === 'center') {
			contentStyle.left = '-50%';
		} else {
			contentStyle.left = 0;
		}

		// set content anchor position y
		if (!!contentPositionY && contentPositionY === 'top') {
			contentStyle.bottom = anchorHeight;
		} else {
			contentStyle.top = anchorHeight;
		}
		return (
			<div
				className={`${css(styleSheet.dropdown, styles)} ${className || ''}`}
				ref={this.onRef}
				onMouseEnter={openOnHover ? this.onMouseEnter : undefined}
				onMouseLeave={openOnHover ? this.onMouseLeave : undefined}
			>
				<Measure client={true} onResize={this.onAnchorResized}>
					{({ measureRef }) => {
						return (
							<div
								ref={measureRef}
								className={`dropdown-anchor ${anchorClassName || ''} ${disabled ? 'disabled' : ''}`}
							>
								<div
									className={css(styleSheet.dropdownAnchorWrap)}
									onClick={!disabled && !!openOnClick ? this.onAnchorClicked : undefined}
								>
									{anchor}
								</div>
							</div>
						);
					}}
				</Measure>
				{!!isOpen && !disabled && (
					<>
						<Measure client={true} onResize={this.onContentSize}>
							{({ measureRef }) => {
								return (
									<div ref={measureRef}>
										<div
											ref={this.contentRef}
											className={`${css(styleSheet.dropdownContent)} ${contentClassName || ''}`}
											style={contentStyle}
										>
											<menu
												className={`${css(styleSheet.dropdownContentInner, ...menuStyles)} ${
													contentInnerClassName || ''
												}`}
											>
												{children}
											</menu>
										</div>
									</div>
								);
							}}
						</Measure>

						<div className={`${css(styleSheet.dropdownBackdrop)}`} onClick={this.onClickOutside} />
					</>
				)}
			</div>
		);
	}

	private clearOnHoverTimeout = () => {
		if (this.hoverTimeoutHandle) {
			clearTimeout(this.hoverTimeoutHandle);
			this.hoverTimeoutHandle = null;
		}
	};

	private contentRef = (ref: HTMLDivElement) => {
		this.componentContentRootElement = ref;
		if (this.componentContentRootElement) {
			this.setState({
				contentHeight: this.componentContentRootElement?.clientHeight,
				contentScrollbarWidth:
					this.componentContentRootElement?.offsetWidth - this.componentContentRootElement?.clientWidth,
			});
		}
	};

	private onRef = (ref: HTMLDivElement) => {
		this.componentRootElement = ref;
		if (ref) {
			this.registerClickOutside();
		} else {
			this.unRegisterClickOutside();
		}
	};

	private registerClickOutside = () => {
		this.unRegisterClickOutside();
		if (this.componentRootElement) {
			this.outsideClickHandle = outy([this.componentRootElement], ['click', 'touchstart'], this.onClickOutside);
		}
	};

	private unRegisterClickOutside = () => {
		if (this.outsideClickHandle) {
			this.outsideClickHandle.remove();

			this.outsideClickHandle = null;
		}
	};

	private onClickOutside = () => {
		this.toggleOpen(false);
	};

	private onAnchorResized = (contentRect: ContentRect) => {
		this.setState({
			anchorContentRect: contentRect,
		});
	};

	private onContentSize = (contentRect: ContentRect) => {
		this.setState({
			contentRect,
		});
	};

	private onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		if (Object.prototype.hasOwnProperty.call(this.props, 'openOnHoverDelay')) {
			this.clearOnHoverTimeout();
			this.hoverTimeoutHandle = setTimeout(() => {
				this.toggleOpen(true);
			}, this.props.openOnHoverDelay);
		} else {
			this.toggleOpen(true);
		}
	};

	private onMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		this.toggleOpen(false);
		this.clearOnHoverTimeout();
	};

	private onAnchorClicked = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();

		this.toggleOpen(!this.state.isOpen);
	};

	private toggleOpen = (isOpen: boolean) => {
		if (isOpen !== this.state.isOpen) {
			if (this.props.onOpenChanged) {
				this.props.onOpenChanged(isOpen);
			} else {
				this.setState({
					isOpen,
				});
			}
		}
	};
}
