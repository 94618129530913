import Bluebird from 'bluebird';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { stringify as toQueryStringParams } from 'query-string';
import { dateWithTimeToUTCString, dateWithoutUTCTime, getDisplayName } from '../Utils';
import { AutomationTemplateViewModel } from '../ViewModels';
import * as Api from '../sdk';

export const RealMagicAccountIds = new Set([
	'b63aefc7-585a-4730-a33c-d680f4f87efc', // Production Levitate for Real Magic, Inc (named Real Magic, LLC in admin)
	'225afcd0-a708-4d3d-957d-9370f7c0bba6', // Test Levitate for Real Magic, Inc (named Levitate in admin)
]);

export const RealMagicAIDAAccountIds = new Set([
	'1702547b-cb86-41e4-8229-853b4e96a173', // Production AIDA for Real Magic, Inc
	'25c7088f-91d2-4ebb-b34b-bac9f9a25503', // Test AIDA for Real Magic, Inc
]);

export interface IUserSessionContext extends Api.IUserSession {
	isAuthenticated?: boolean;
	logout?(): void;
	updateWithCredential?(credential: Api.ICredential): Promise<Api.IUserSession>;
	webServiceHelper: Api.WebServiceHelper;
}

export interface IUserSessionContextConfig {
	apiConfig?: Api.IWebServiceHelperConfig;
}

export const DefaultUserSessionConfig: IUserSessionContextConfig = {
	apiConfig: { ...Api.DefaultConfig },
};

export enum MeetingDateRange {
	FutureDays,
	DateRange,
	Indefinitely,
}

export const daysOfWeekStrings = [
	{
		abbr: 'Sun',
		full: 'Sunday',
	},
	{
		abbr: 'Mon',
		full: 'Monday',
	},
	{
		abbr: 'Tues',
		full: 'Tuesday',
	},
	{
		abbr: 'Wed',
		full: 'Wednesday',
	},
	{
		abbr: 'Thur',
		full: 'Thursday',
	},
	{
		abbr: 'Fri',
		full: 'Friday',
	},
	{
		abbr: 'Sat',
		full: 'Saturday',
	},
];

export abstract class BaseViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends Api.ImpersonationBroker {
	@observable.ref protected mUserSession: TUserSession;
	protected webServiceHelper: Api.WebServiceHelper;

	constructor(userSession?: TUserSession) {
		super();
		this.mUserSession = userSession;
		if (userSession) {
			this.webServiceHelper = userSession.webServiceHelper;
		}
	}

	@computed
	public get userSession() {
		return this.mUserSession;
	}

	public set userSession(value: TUserSession) {
		runInAction(() => {
			this.mUserSession = value;
		});
	}

	@computed
	public get isAdmin(): boolean {
		const role = (this instanceof UserSessionContext ? this : this.mUserSession)?.userRole?.toLocaleLowerCase();
		return role === 'admin' || role === 'superadmin';
	}

	/**
	 * Even if true, doesn't mean we have a valid user for the impersonation context. This vm is just allowed to do
	 * "admin"-level things.
	 */
	@computed
	public get isImpersonatingAccountAdmin(): boolean {
		if (this.isImpersonating) {
			return this.impersonationContext.user
				? Api.isAdmin(this.impersonationContext.user)
				: !!this.impersonationContext.account;
		}
		return false;
	}
}

export class ViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends BaseViewModel<TUserSession> {
	@observable protected busy: boolean;
	@observable protected loaded: boolean;
	@observable protected loading: boolean;

	constructor(userSession?: TUserSession) {
		super(userSession);
		this.loading = false;
		this.loaded = false;
		this.busy = false;
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	public get isLoading() {
		return this.loading;
	}

	@computed
	public get isLoaded() {
		return this.loaded;
	}

	@action
	public load(): Promise<any> {
		return Promise.resolve(null);
	}
}

export class MeetingConfigViewModel extends ViewModel {
	@observable private mMeetingConfig: Api.IMeetingConfig;
	@observable private saving = false;
	@observable private automationChoices: AutomationTemplateViewModel[] = [];
	public forUser: Api.IUser;

	public static INDEFINITELY_IN_DAYS = 1830;
	public static FUTURE_DAYS_TO_LOOK_AHEAD = 180;

	constructor(userSession: UserSessionContext, config: Api.IMeetingConfigResponse, forUser?: Api.IUser) {
		super(userSession);
		this.setConfig(config);
		this.setForUser(forUser);
	}

	@computed
	get availability() {
		return this.mMeetingConfig.draft?.availability ?? this.mMeetingConfig.published?.availability;
	}

	get baseRoute() {
		return `scheduler/config/${this.mMeetingConfig.id}`;
	}

	@computed
	get confirmationNote() {
		return this.mMeetingConfig.draft?.confirmationNote ?? this.mMeetingConfig.published?.confirmationNote;
	}

	set confirmationNote(content: Api.IRawRichTextContentState) {
		this.updateProperty('confirmationnote', 'PUT', content);
	}

	@computed
	get duration() {
		return this.mMeetingConfig.draft?.duration ?? this.mMeetingConfig.published?.duration;
	}

	set duration(duration: Api.IMeetingDuration) {
		this.updateProperty('duration', 'PUT', this.convertDurationToString(duration));
	}

	@computed
	get id() {
		return this.mMeetingConfig.id;
	}

	@computed
	get includeInEmailSignature() {
		return this.mMeetingConfig.draft?.includeInEmailSignature ?? this.mMeetingConfig.published?.includeInEmailSignature;
	}

	@computed
	get isBusy() {
		return this.busy;
	}

	@computed
	get isSaving() {
		return this.saving;
	}

	@computed
	get link() {
		return this.mMeetingConfig.draft?.url ?? this.mMeetingConfig.published?.url;
	}

	@computed
	get locationConfig() {
		return this.mMeetingConfig.draft?.locationConfig ?? this.mMeetingConfig.published?.locationConfig;
	}

	set locationConfig(config:
		| Api.IPhoneMeetingLocationConfig
		| Api.IAllowInviteeLocationConfig
		| Api.IOtherMeetingLocationConfig
		| Api.IInPersonLocationConfig
		| Api.IVirtualLocationConfig) {
		switch (config._type) {
			case Api.MeetingType.Phone:
				this.updateProperty('location/phone', 'PUT', config);
				break;
			case Api.MeetingType.AllowInvitee:
				this.updateProperty('location/allowInvitee', 'PUT', config);
				break;
			case Api.MeetingType.InPerson:
				this.updateProperty('location/inPerson', 'PUT', config);
				break;
			case Api.MeetingType.Virtual:
				this.updateProperty('location/virtual', 'PUT', config);
				break;
			default:
				break;
		}
	}

	@action
	public updateAllowInviteeLocation(
		location: Api.IPhoneMeetingLocation | Api.IInPersonMeetingLocation | Api.IVirtualMeetingLocation
	) {
		switch (location._type) {
			case Api.MeetingLocation.Phone:
				return this.updateProperty('location/allowInvitee/phone', 'PUT', location);
			case Api.MeetingLocation.InPerson:
				return this.updateProperty('location/allowInvitee/inPerson', 'PUT', location);
			case Api.MeetingLocation.Virtual:
				return this.updateProperty('location/allowInvitee/virtual', 'PUT', location);
			default:
				return null;
		}
	}

	@action
	public deleteAllowInviteeLocation(location: Api.MeetingLocation) {
		switch (location) {
			case Api.MeetingLocation.Phone:
				this.updateProperty('location/allowInvitee/phone', 'DELETE');
				break;
			case Api.MeetingLocation.InPerson:
				this.updateProperty('location/allowInvitee/inPerson', 'DELETE');
				break;
			case Api.MeetingLocation.Virtual:
				this.updateProperty('location/allowInvitee/virtual', 'DELETE');
				break;
			default:
				break;
		}
	}

	@action
	public getDefaultAddress() {
		if (this.locationConfig?._type === Api.MeetingType.InPerson) {
			return (this.locationConfig as Api.IInPersonLocationConfig).address;
		}
		return this.allowInviteeInPerson?.address || '';
	}

	@action
	public getDefaultVirtualLink() {
		if (this.locationConfig?._type === Api.MeetingType.Virtual) {
			return (this.locationConfig as Api.IVirtualLocationConfig).link;
		}
		return this.allowInviteeVirtual?.link || '';
	}

	@computed
	get allowInviteePhone() {
		if (this.locationConfig?._type === Api.MeetingType.AllowInvitee) {
			return (this.locationConfig as Api.IAllowInviteeLocationConfig)?.locations?.find(
				x => x._type === Api.MeetingLocation.Phone
			);
		}
		return null;
	}

	@computed
	get allowInviteeInPerson() {
		if (this.locationConfig?._type === Api.MeetingType.AllowInvitee) {
			return (this.locationConfig as Api.IAllowInviteeLocationConfig)?.locations?.find(
				x => x._type === Api.MeetingLocation.InPerson
			) as Api.IInPersonMeetingLocation;
		}
		return null;
	}

	@computed
	get allowInviteeVirtual() {
		if (this.locationConfig?._type === Api.MeetingType.AllowInvitee) {
			return (this.locationConfig as Api.IAllowInviteeLocationConfig)?.locations?.find(
				x => x._type === Api.MeetingLocation.Virtual
			) as Api.IVirtualMeetingLocation;
		}
		return null;
	}

	@computed
	get atLeastInterval() {
		return this.mMeetingConfig?.draft?.atLeastInterval ?? this.mMeetingConfig?.published?.atLeastInterval;
	}

	@computed
	get atLeastDays() {
		return this.atLeastInterval ? parseInt(this.atLeastInterval, 10) : 0;
	}

	@action
	public async setAtLeastInterval(hours: number, days?: number) {
		const interval = `${days ? `${days}.` : ''}${String(hours).padStart(2, '0')}:00:00`;
		await this.updateProperty(`atLeastInterval?atLeastInterval=${interval}`, 'PUT');
	}

	@computed
	get atMostInterval() {
		return this.mMeetingConfig?.draft?.atMostInterval ?? this.mMeetingConfig?.published?.atMostInterval;
	}

	@computed
	get atMostDays() {
		return this.atMostInterval ? parseInt(this.atMostInterval, 10) : MeetingConfigViewModel.FUTURE_DAYS_TO_LOOK_AHEAD;
	}
	@computed
	get bufferAfterInterval() {
		return this.mMeetingConfig?.draft?.bufferAfterInterval ?? this.mMeetingConfig?.published?.bufferAfterInterval;
	}

	@computed
	get bufferBeforeInterval() {
		return this.mMeetingConfig?.draft?.bufferBeforeInterval ?? this.mMeetingConfig?.published?.bufferBeforeInterval;
	}

	@action
	public async setBufferBeforeInterval(interval: string) {
		await this.updateProperty(`BufferBeforeInterval?bufferBeforeInterval=${interval}`, 'PUT');
	}

	@action
	public async setBufferAfterInterval(interval: string) {
		await this.updateProperty(`BufferAfterInterval?bufferAfterInterval=${interval}`, 'PUT');
	}

	@action
	public async setAtMostInterval(atMostDays: number) {
		await this.updateProperty('dateRange', 'DELETE');
		await this.updateProperty(`atMostInterval?atMostDays=${encodeURIComponent(atMostDays)}`, 'PUT');
	}

	@computed
	get dateRange() {
		return this.mMeetingConfig?.draft?.range ?? this.mMeetingConfig?.published?.range;
	}

	@action
	public setDateRange(start: Date, end: Date) {
		this.updateProperty(
			`dateRange?start=${encodeURIComponent(dateWithTimeToUTCString(start, 0, 0, 0))}&end=${encodeURIComponent(
				dateWithTimeToUTCString(end, 23, 59, 59)
			)}`,
			'PUT'
		);
	}

	@action
	public updateCustomFieldMetaData(fieldName: string, include: boolean, require: boolean) {
		this.updateProperty(
			`CustomField/${encodeURIComponent(fieldName)}?include=${encodeURIComponent(include)}&require=${encodeURIComponent(
				require
			)}`,
			'PUT'
		);
	}

	@computed
	get defaultDateRange() {
		if (this.dateRange?.start && this.dateRange?.end) {
			const start = dateWithoutUTCTime(this.dateRange.start);
			const end = dateWithoutUTCTime(this.dateRange.end);
			return {
				from: start,
				to: end,
			};
		}
		const endDate = new Date();
		endDate.setDate(endDate.getDate() + 14);
		return { from: new Date(), to: endDate };
	}

	@computed
	get meetingDateRadio() {
		if (this.dateRange?.start && this.dateRange?.end) {
			return MeetingDateRange.DateRange;
		}
		return this.atMostDays === MeetingConfigViewModel.INDEFINITELY_IN_DAYS
			? MeetingDateRange.Indefinitely
			: MeetingDateRange.FutureDays;
	}

	@computed
	get name() {
		return this.mMeetingConfig.draft?.name ?? this.mMeetingConfig.published?.name;
	}

	set name(name: string) {
		this.updateProperty(`name?name=${encodeURIComponent(name)}`, 'PUT');
	}

	@computed
	get shortCode() {
		return this.mMeetingConfig.draft?.shortCode ?? this.mMeetingConfig.published?.shortCode;
	}

	@computed
	get vanityPath() {
		return this.mMeetingConfig.draft?.vanityPath ?? this.mMeetingConfig.published?.vanityPath;
	}

	@computed
	get ccEmails() {
		return this.mMeetingConfig.draft?.ccEmails ?? this.mMeetingConfig.published?.ccEmails;
	}

	set ccEmails(CcEmails: string[]) {
		this.updateProperty(`CcEmails`, 'PUT', CcEmails);
	}

	@computed
	get slotStartInMinutes() {
		return this.mMeetingConfig.draft?.slotStartInMinutes ?? this.mMeetingConfig?.published?.slotStartInMinutes;
	}

	set slotStartInMinutes(SlotStartInMinutes: number[]) {
		this.updateProperty(`SlotStartInMinutes`, 'PUT', SlotStartInMinutes);
	}

	@computed
	get isPublished() {
		return !!this.mMeetingConfig?.published;
	}

	@computed
	get showOnHomepage() {
		return this.mMeetingConfig.draft?.showOnHomepage ?? this.mMeetingConfig.published?.showOnHomepage;
	}

	set showOnHomepage(showOnHomepage: boolean) {
		this.updateProperty(`showOnHomepage?showOnHomepage=${encodeURIComponent(showOnHomepage)}`, 'PUT');
	}

	@computed
	get requireCompanyOnMainParticipant() {
		return (
			this.mMeetingConfig.draft?.requireCompanyOnMainParticipant ??
			this.mMeetingConfig.published?.requireCompanyOnMainParticipant
		);
	}

	set requireCompanyOnMainParticipant(value: boolean) {
		this.updateProperty(
			`RequireCompanyOnMainParticipant?RequireCompanyOnMainParticipant=${encodeURIComponent(value)}`,
			'PUT'
		);
	}

	@computed
	get commentsRequired() {
		return this.mMeetingConfig.draft?.commentsRequired ?? this.mMeetingConfig.published?.commentsRequired;
	}

	set commentsRequired(value: boolean) {
		this.updateProperty(`CommentsRequired?commentsRequired=${encodeURIComponent(value)}`, 'PUT');
	}

	@computed
	get commentsLabel() {
		return this.mMeetingConfig.draft?.commentsLabel ?? this.mMeetingConfig.published?.commentsLabel;
	}

	set commentsLabel(value: string) {
		this.updateProperty(`CommentsLabel?commentsLabel=${encodeURIComponent(value)}`, 'PUT');
	}

	@computed
	get contactFormFields() {
		return this.mMeetingConfig.draft?.contactFormFields ?? this.mMeetingConfig.published?.contactFormFields;
	}

	@computed
	get addMeetingNameToCalendarEvent() {
		return (
			this.mMeetingConfig.draft?.addMeetingNameToCalendarEvent ??
			this.mMeetingConfig.published?.addMeetingNameToCalendarEvent
		);
	}

	set addMeetingNameToCalendarEvent(addMeetingNameToCalendarEvent: boolean) {
		this.updateProperty(
			`addMeetingNameToCalendarEvent?addMeetingNameToCalendarEvent=${encodeURIComponent(
				addMeetingNameToCalendarEvent
			)}`,
			'PUT'
		);
	}

	@computed
	get maxPerDay() {
		return this.mMeetingConfig.draft?.maxPerDay ?? this.mMeetingConfig.published?.maxPerDay;
	}

	set maxPerDay(maxPerDay: number) {
		this.updateProperty(`maxPerDay?maxPerDay=${encodeURIComponent(maxPerDay)}`, 'PUT');
	}

	@computed
	get maxPerMonth() {
		return this.mMeetingConfig.draft?.maxPerMonth ?? this.mMeetingConfig.published?.maxPerMonth;
	}

	set maxPerMonth(maxPerMonth: number) {
		this.updateProperty(`maxPerMonth?maxPerMonth=${encodeURIComponent(maxPerMonth)}`, 'PUT');
	}

	@computed
	get allowSameDay() {
		return this.atLeastInterval === '00:00:00';
	}

	set allowSameDay(allowSameDay: boolean) {
		this.updateProperty(`allowSameDay?allowSameDay=${encodeURIComponent(allowSameDay)}`, 'PUT');
	}

	@computed
	get automationChoicesList() {
		return this.automationChoices;
	}

	@computed
	get meetingReminderTemplateId() {
		return (
			this.mMeetingConfig.draft?.meetingReminderTemplateId ?? this.mMeetingConfig.published?.meetingReminderTemplateId
		);
	}

	set meetingReminderTemplateId(id: string) {
		this.updateProperty(`meetingReminderTemplateId?meetingReminderTemplateId=${encodeURIComponent(id)}`, 'PUT');
	}

	@action
	public async setAsEditing() {
		if (!this.busy) {
			this.busy = true;
			try {
				const opResult = await this.userSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: `${this.baseRoute}/edit` }),
					'POST'
				);

				if (opResult.success) {
					this.setConfig(opResult.value);
				} else {
					throw Api.asApiError(opResult);
				}

				this.busy = false;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	}

	@action
	public setAvailability(day: Api.DayOfWeek, availability?: Api.IDailyIntervals) {
		return this.updateProperty(
			`availability/${daysOfWeekStrings[day].full.toLocaleLowerCase()}`,
			availability ? 'PUT' : 'DELETE',
			availability
		);
	}

	@action
	public async commitChanges() {
		if (!this.busy) {
			this.busy = true;
			try {
				const opResult = await this.userSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({
						queryParams: {
							forUserId: this.forUser?.id,
						},
						urlPath: `${this.baseRoute}/commit`,
					}),
					'POST'
				);

				if (opResult.success) {
					this.setConfig(opResult.value);
				} else {
					throw Api.asApiError(opResult);
				}

				this.busy = false;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	}

	@action
	public async discardChanges() {
		if (!this.busy) {
			this.busy = true;
			try {
				const opResult = await this.userSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({
						queryParams: {
							forUserId: this.forUser?.id,
						},
						urlPath: `${this.baseRoute}/discard`,
					}),
					'POST'
				);

				if (opResult.success) {
					this.setConfig(opResult.value);
				} else {
					throw Api.asApiError(opResult);
				}

				this.busy = false;
			} catch (err) {
				this.busy = false;
				throw Api.asApiError(err);
			}
		}
	}

	@action
	public updateProperty(url: string, method: Api.HTTPMethod, body: any = null) {
		if (!this.saving) {
			this.saving = true;
			const forUserIdParam = this.forUser?.id ? `${url.includes('?') ? '&' : '?'}forUserId=${this.forUser?.id}` : '';
			return this.userSession.webServiceHelper
				.callWebServiceAsync(
					this.composeApiUrl({
						urlPath: `${this.baseRoute}/${url}${forUserIdParam}`,
					}),
					method,
					body
				)
				.then(result => {
					if (result.success) {
						runInAction(() => {
							this.setConfig(result.value);
							this.saving = false;
						});
					} else {
						runInAction(() => {
							this.saving = false;
						});
						throw Api.asApiError(result);
					}
				})
				.catch((err: Api.IOperationResultNoValue) => {
					runInAction(() => {
						this.saving = false;
					});
					throw Api.asApiError(err);
				});
		}

		return null;
	}

	@action
	public async getAutomationChoices() {
		try {
			const data: Api.IOperationResult<AutomationTemplateViewModel[]> =
				await this.userSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: `automationTemplate/meetingReminders` }),
					'GET'
				);
			this.automationChoices = data.value;
		} catch (err) {
			throw Api.asApiError(err);
		}
	}

	/**
	 * Parses the duration string in format: d.hh:mm:ss into Api.IMeetingDuration format.
	 *
	 * @param {string} durationString
	 * @returns {Api.IMeetingDuration}
	 */
	private convertDurationFromString(durationString: string) {
		let rest = durationString;
		const parsed: Api.IMeetingDuration = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};

		if (durationString.includes('.')) {
			const [days, r] = durationString.split('.');
			parsed.days = parseInt(days, 10);
			rest = r;
		}

		const [h, m, s] = rest.split(':');
		return {
			...parsed,
			hours: parseInt(h, 10),
			minutes: parseInt(m, 10),
			seconds: parseInt(s, 10),
		};
	}

	/**
	 * Converts an Api.IMeetingDuration to a string in format d.hh:mm:ss
	 *
	 * @param {Api.IMeetingDuration} duration
	 * @returns {string}
	 */
	private convertDurationToString(duration: Api.IMeetingDuration) {
		const { days, hours, minutes, seconds } = duration;
		const d = days <= 9 ? `0${days}` : days;
		const h = hours <= 9 ? `0${hours}` : hours;
		const m = minutes <= 9 ? `0${minutes}` : minutes;
		const s = seconds <= 9 ? `0${seconds}` : seconds;
		return `${d}.${h}:${m}:${s}`;
	}

	@action
	private setConfig(configResponse: Api.IMeetingConfigResponse) {
		const config: Partial<Api.IMeetingConfig> = {};

		Object.entries(configResponse).forEach(([key, value]) => {
			let duration: Api.IMeetingDuration;
			switch (key) {
				case 'draft':
					duration = this.convertDurationFromString(value.duration);
					config.draft = { ...value, duration };
					break;
				case 'published':
					duration = this.convertDurationFromString(value.duration);
					config.published = { ...value, duration };
					break;
				default:
					// FOLLOWUP: Resolve

					// @ts-ignore
					config[key] = value;
					break;
			}
		});

		this.mMeetingConfig = config;
	}

	@action
	private setForUser(forUser: Api.IUser) {
		this.forUser = forUser;
	}
}

export class UserViewModel<TUser extends Api.IUser = Api.IUser> extends ViewModel {
	@observable protected deleting: boolean;
	@observable protected mMeetingConfigs: MeetingConfigViewModel[];
	@observable protected mUser: TUser;
	@observable protected mUploadingProfilePic = false;
	protected loadingPromise: Promise<TUser>;

	constructor(userSession: UserSessionContext, user: TUser) {
		super(userSession);
		this.mSetUser(user);
	}

	@computed
	public get uploadingProfilePic() {
		return this.mUploadingProfilePic;
	}

	@computed
	public get firstName() {
		return this.mUser.firstName;
	}

	@computed
	public get lastName() {
		return this.mUser.lastName;
	}

	@computed
	public get name() {
		return getDisplayName(this.mUser);
	}

	@computed
	public get email() {
		return this.mUser.email;
	}

	@computed
	public get connectedEmailAddress() {
		return this.mUser.connectedEmailAddress;
	}

	@computed
	public get userMilestones() {
		return this.mUser.userMilestones;
	}

	@computed
	public get emailProvider() {
		return this.mUser.emailProvider;
	}

	@computed
	public get preferences() {
		return this.mUser.userPreferences;
	}

	@computed
	public get role() {
		return this.mUser.role;
	}

	@computed
	public get profilePicUrl() {
		return this.mUser.profilePic;
	}

	@computed
	public get groups() {
		return this.mUser.groups;
	}

	@computed
	public get contactId() {
		return this.mUser.contactId;
	}

	@computed
	public get activationStatus() {
		return this.mUser.activationStatus;
	}

	@computed
	public get accountId() {
		return this.mUser.accountId;
	}

	@computed
	public get id() {
		return this.mUser.id;
	}

	@computed
	public get isDeleting() {
		return this.deleting;
	}

	@computed
	public get isBusy() {
		return this.loading || this.busy || this.deleting;
	}

	@computed
	public get meetingConfigs() {
		return this.mMeetingConfigs || [];
	}

	@computed
	public get socialMediaConnectedAccounts() {
		return this.mUser.socialMediaConnectedAccounts;
	}

	@action
	public load(force = false) {
		if (this.isLoaded && !force) {
			return Promise.resolve(this.mUser);
		}

		if (!this.loadingPromise) {
			this.loading = true;
			this.loadingPromise = new Promise<TUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.loading = false;
						this.loadingPromise = null;
						if (result.success) {
							this.mSetUser(result.value);
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TUser>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}` }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return this.loadingPromise;
	}

	@action
	public setUser = (user: TUser) => {
		this.mSetUser(user);
	};

	@action
	public toggleAdminUserRole = (makeAdmin: boolean) => {
		if (makeAdmin === (this.mUser.groups && this.mUser.groups.findIndex(x => x === 'admin') >= 0)) {
			return Promise.resolve<Api.IUser>(this.mUser);
		}

		if (!this.busy) {
			this.busy = true;
			return new Promise<TUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mSetUser(result.value);
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TUser>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}/${makeAdmin ? 'makeAdmin' : 'removeAdmin'}` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public toggleActive = (active: boolean) => {
		if (active === (this.mUser.activationStatus === 'ACTIVE' ? true : false)) {
			return Promise.resolve<TUser>(this.mUser);
		}

		if (!this.busy) {
			this.busy = true;
			return new Promise<TUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mSetUser(result.value);
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TUser>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}/${active ? 'reactivate' : 'deactivate'}` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public delete = () => {
		if (!this.isBusy) {
			this.deleting = true;
			return new Promise<TUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.deleting = false;
						if (result.success) {
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TUser>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public update = (user: TUser) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<TUser>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mSetUser(result.value);
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<TUser>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}` }),
					'PUT',
					user,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public updateUserPreferences = (userPreferences: Api.IUserPreferences) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IUserPreferences>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IUserPreferences>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mUser.userPreferences = result.value;
							this.userSession.user.userPreferences = result.value;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IUserPreferences>(
					this.composeApiUrl({ urlPath: 'user/preferences' }),
					'PUT',
					userPreferences,
					onFinish,
					onFinish
				);
			});
		}
		return null;
	};

	@action
	public updateKeepInTouchCommitmentPreferences = (kitCommitmentPreferences: Api.IKeepInTouchCommitmentPreferences) => {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IKeepInTouchCommitmentPreferences>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IKeepInTouchCommitmentPreferences>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mUser.userPreferences.keepInTouchCommitmentPreferences = result.value;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IKeepInTouchCommitmentPreferences>(
					this.composeApiUrl({ urlPath: `user/${this.mUser.id}/keepInTouchCommitmentPreferences` }),
					'PUT',
					kitCommitmentPreferences,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public updateUnsubscribeTemplateId = (templateId: string) => {
		if (!this.busy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<TUser>>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mSetUser(result.value);
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: {
							templateId,
						},
						urlPath: 'user/preferences/unsubscribeTemplate',
					}),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public updateTextAutoReplySettings = (textAutoReply: Api.ITextAutoReply) => {
		if (!this.busy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<TUser>>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<TUser>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mSetUser(result.value);
							this.userSession.user.textAutoReply = result.value.textAutoReply;
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: 'user/textAutoReply' }),
					'PUT',
					textAutoReply,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	public toJs = () => {
		return this.mUser;
	};

	protected mSetUser = (user: TUser) => {
		this.mUser = user;
	};
}

export class AccountViewModel extends ViewModel {
	@observable private saving: boolean;
	@observable.ref protected mAccount: Api.IAccount;
	private mUsersPageCollectionController: ObservablePageCollectionControllerOld<Api.IUser, UserViewModel>;
	private loadingPromise: Promise<Api.IAccount>;

	constructor(userSession: UserSessionContext, account: Api.IAccount) {
		super(userSession);
		this.mAccount = account;
		this.mUsersPageCollectionController = new ObservablePageCollectionControllerOld<Api.IUser, UserViewModel>(
			userSession.webServiceHelper,
			'user',
			null,
			this.createUserViewModel
		);
	}

	public get isPersonalAccount() {
		return this.userSession?.account?.planDetails?.planId === 0;
	}

	@computed
	public get isPaid() {
		return this.mAccount.planDetails.billingType === Api.BillingType.Paid;
	}

	@computed
	public get isTrial() {
		return this.mAccount.planDetails.billingType === Api.BillingType.Trial;
	}

	@computed
	public get integrations() {
		return this.mAccount.integrations;
	}

	@computed
	public get isFetchingUsers() {
		return this.mUsersPageCollectionController.fetching;
	}

	@computed
	public get id() {
		return this.mAccount.id;
	}

	@computed
	public get additionalInfo() {
		return this.mAccount?.additionalInfo;
	}

	@computed
	public get planDetails() {
		return this.mAccount.planDetails;
	}

	@computed
	public get emailDomain() {
		return this.mAccount.emailDomain;
	}

	@computed
	public get creationDate() {
		return this.mAccount.creationDate;
	}

	@computed
	public get activationStatus() {
		return this.mAccount.activationStatus;
	}

	@computed
	public get emailProviderConfiguration() {
		return this.mAccount.emailProviderConfigurations;
	}

	@computed
	public get companyName() {
		return this.mAccount.companyName;
	}

	@computed
	public get features() {
		return this.mAccount.features;
	}

	@computed
	public get preferences() {
		return this.mAccount.preferences;
	}

	@computed
	public get isSaving() {
		return this.saving;
	}

	@computed
	public get isBusy() {
		return this.loading || this.busy || this.saving || this.mUsersPageCollectionController.fetching;
	}

	@computed
	public get isLevitateSalesCoffeeAccount() {
		return RealMagicAIDAAccountIds.has(this.id);
	}

	@computed
	public get isRealMagicAccount() {
		return RealMagicAccountIds.has(this.id);
	}

	@action
	public reset = () => {
		this.mUsersPageCollectionController.reset();
		this.loadingPromise = null;
		this.loading = false;
		this.busy = false;
		this.saving = false;
	};

	@action
	public load(force = false) {
		if (this.isLoaded && !force) {
			return Promise.resolve(this.mAccount);
		}

		if (!this.loadingPromise) {
			this.loading = true;
			this.loadingPromise = new Promise<Api.IAccount>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						this.loading = false;
						this.loadingPromise = null;
						if (result.success) {
							this.mAccount = result.value;
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`account/${this.mAccount.id}`,
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return this.loadingPromise;
	}

	@action
	public save = (account: Api.IAccount) => {
		if (!this.saving) {
			this.saving = true;
			const promise = new Promise<Api.IAccount>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						this.saving = false;
						if (result.success) {
							this.mAccount = result.value;
							this.loaded = true;
							resolve(result.value);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IAccount>(
					`account/${this.mAccount.id}`,
					'PUT',
					account,
					onFinish,
					onFinish
				);
			});
			return promise;
		}

		return null;
	};

	@action
	public getUserCount = () => {
		return new Promise<number>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<number>) => {
				if (result.success) {
					resolve(result.value);
				} else {
					reject(result);
				}
			};
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<number>(
				`account/${this.mAccount.id}/userCount`,
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	@action
	public updateUnsubscribeTemplateId = (templateId: string) => {
		if (!this.busy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAccount>>((resolve, reject) => {
				const onFinish = (result: Api.IOperationResult<Api.IAccount>) => {
					runInAction(() => {
						this.busy = false;
						if (result.success) {
							this.mAccount = result.value;
							resolve(result);
						} else {
							reject(result);
						}
					});
				};
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					`account/preferences/unsubscribeTemplateId?templateId=${encodeURIComponent(templateId)}`,
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return null;
	};

	@action
	public async updateHtmlNewsletterFeatures(features: Api.IHtmlNewsletterFeatures) {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IHtmlNewsletterFeatures>(
				`account/features/htmlNewsletter`,
				'PUT',
				features
			);
			if (opResult.success) {
				this.mAccount = {
					...this.mAccount,
					features: {
						...this.mAccount.features,
						htmlNewsletter: opResult.value,
					},
				};
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public async updateMeetingSchedulerFeatures(features: Api.IFeature) {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IFeature>(
				`account/features/meetingScheduler`,
				'PUT',
				features
			);
			if (opResult.success) {
				this.mAccount = {
					...this.mAccount,
					features: {
						...this.mAccount.features,
						meetingScheduler: opResult.value,
					},
				};
			}
			this.busy = false;
			return opResult;
		}
	}

	@action
	public setIntegration<T extends Api.IConfigurableIntegration>(key: keyof Api.IAccountIntegrations, integration: T) {
		this.mAccount = {
			...this.mAccount,
			integrations: {
				...(this.mAccount.integrations || {}),
				[key]: integration,
			},
		};
	}

	@action
	public getUsers = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number, params?: any) => {
		return this.mUsersPageCollectionController.getNext(sortDescriptor, pageSize, params);
	};

	public toJs = () => {
		return this.mAccount;
	};

	private createUserViewModel = (userModel: Api.IUser) => {
		return new UserViewModel(this.mUserSession, userModel);
	};

	public setIngration<T extends Api.IConfigurableIntegration>(key: keyof Api.IAccountIntegrations, integration: T) {
		this.mAccount = {
			...this.mAccount,
			integrations: {
				...(this.mAccount.integrations || {}),
				[key]: integration,
			},
		};
	}
}

export class UserSessionContext extends ViewModel implements IUserSessionContext {
	@observable protected requestCount: number;
	@observable.ref private mAccount: AccountViewModel;
	@observable.ref protected mApiUserSession: Api.IUserSession;
	@observable.ref
	protected mSupportedPluginFeatures: Api.ISupportedPluginFeatures;
	protected config: IUserSessionContextConfig;
	public webServiceHelper: Api.WebServiceHelper;

	constructor(config?: IUserSessionContextConfig) {
		super();
		this.setUserSession = this.setUserSession.bind(this);
		this.getStoredCredential = this.getStoredCredential.bind(this);
		this.logout = this.logout.bind(this);
		this.requestCount = 0;
		this.config = { ...DefaultUserSessionConfig, ...config };
		this.webServiceHelper = new Api.WebServiceHelper(this.config.apiConfig);
		this.webServiceHelper.on(Api.WebServiceHelper.EventNames.REQUEST_START, this.onRequestStart);
		this.webServiceHelper.on(Api.WebServiceHelper.EventNames.REQUEST_END, this.onRequestEnd);
	}

	@computed
	public get emailProviderUsesBasicCredentials() {
		const emailProvider =
			this.mApiUserSession && this.mApiUserSession.user ? this.mApiUserSession.user.emailProvider : null;
		if (emailProvider) {
			return emailProvider === 'Exchange' || emailProvider === 'Imap';
		}
		return false;
	}

	@computed
	public get account() {
		return this.mAccount;
	}

	@computed
	public get apiRequestCount() {
		return this.requestCount;
	}

	@computed
	public get isBusy() {
		return this.loading || this.busy;
	}

	@computed
	public get isAuthenticated() {
		return !!this.mApiUserSession;
	}

	@computed
	public get user() {
		return this.isAuthenticated ? this.mApiUserSession.user : null;
	}

	@computed
	public get telephonyConfiguration() {
		return this.mApiUserSession?.telephonyConfiguration;
	}

	@computed
	public get coffeeKeyFieldMappings() {
		return this.mApiUserSession?.coffeeKeyFieldMappings;
	}

	@computed
	public get pendingActions() {
		return (this.mApiUserSession || {}).pendingActions || [];
	}

	@computed
	public get pendingAccountActions() {
		return (this.mApiUserSession || {}).pendingAccountActions || [];
	}

	@computed
	public get featureFlags() {
		return (this.mApiUserSession || {}).featureFlags || [];
	}

	@computed
	public get newFeatures() {
		return (this.mApiUserSession || {}).newFeatures || [];
	}

	@computed
	public get isCustomerImpersonation() {
		return (this.mApiUserSession || {}).isCustomerImpersonation;
	}

	@computed get isAdmin() {
		return this.mApiUserSession.user?.role?.toLocaleLowerCase()?.includes('admin');
	}

	@computed
	public get sendOnBehalfIsEnabled() {
		return this.mApiUserSession.account?.preferences?.sendOnBehalfEnabled;
	}

	@computed
	public get canSendOnBehalf() {
		if (!this.sendOnBehalfIsEnabled) {
			return false;
		}

		return (
			this.defaultSendOnBehalfPermissions?.filter(x => x.sender?.mode !== Api.SendEmailFrom.CurrentUser).length > 0 ||
			this.customSendOnBehalfPermissions?.length > 0
		);
	}

	@computed
	public get defaultSendOnBehalfPermissions() {
		return this.mApiUserSession.user.defaultSendOnBehalfPermissions;
	}

	@computed
	public get customSendOnBehalfPermissions() {
		return this.mApiUserSession.user.customSendOnBehalfPermissions;
	}

	@computed
	public get levitateUIStimulant() {
		return this.mApiUserSession?.levitateUIStimulant ?? Api.LevitateUIStimulant.Caffeinated;
	}

	@computed
	public get supportedPluginFeatures() {
		return this.mSupportedPluginFeatures;
	}

	@action
	public setSupportedPluginFeatures(features: Api.ISupportedPluginFeatures) {
		this.mSupportedPluginFeatures = features;
	}

	@action
	public resolvePendingAction(resolvedAction: Api.PendingActions) {
		this.mApiUserSession = {
			...this.mApiUserSession,
			pendingActions: (this.mApiUserSession.pendingActions || []).filter(a => a !== resolvedAction),
		};
	}

	@action
	public clearPendingActions = () => {
		if (this.mUserSession) {
			this.mApiUserSession = {
				...this.mApiUserSession,
				pendingActions: [],
			};
		}
	};

	@action
	public removePendingAccountAction = (id: string) => {
		if (this.mUserSession) {
			this.mApiUserSession = {
				...this.mApiUserSession,
				pendingAccountActions: this.pendingAccountActions.filter(a => a.id !== id),
			};
		}
	};

	@computed
	public get userRole() {
		if (!this.user) {
			return 'user';
		}

		if (this.user.role) {
			return this.user.role;
		}

		return this.user.groups.indexOf('admin') > -1 ? 'admin' : 'user';
	}

	@computed
	public get hasAnyEmailPendingActions() {
		return this.needsToReconnectEmail || this.needsEmailConnect;
	}

	@computed
	public get needsToReconnectEmail() {
		return this.pendingActions.indexOf(Api.PendingActions.ReconnectEmail) > -1;
	}

	@computed
	public get needsEmailConnect() {
		return (
			this.pendingActions.indexOf(Api.PendingActions.ConnectEmailInitial) > -1 ||
			this.pendingActions.indexOf(Api.PendingActions.ConnectEmailInvalidateAccess) > -1
		);
	}

	/** Main login call */
	@action
	public updateWithCredential = (request: Api.IAuthenticationRequest) => {
		return new Promise<Api.IUserSession>((resolve, reject) => {
			const onError = (error: Api.IOperationResult<Api.IAuthenticationResponse>) => {
				runInAction(() => {
					this.logout();
					this.busy = false;
					reject(error);
				});
			};

			const onSuccess = async (opResult: Api.IOperationResult<Api.IAuthenticationResponse>) => {
				if (!opResult.success) {
					onError(opResult);
					return;
				}

				/** Check for an SMS authenticator "error" that requires more info */
				const response = opResult.value;
				if (!response?.success) {
					onError(opResult);
					return;
				}

				try {
					const credentialStore = this.webServiceHelper.getCredentialStore();
					await credentialStore.setCredential(opResult.value.token);
					const userSession = await this.getUserSessionWithCredential(opResult.value.token);
					runInAction(() => {
						this.busy = false;
						resolve(userSession);
					});
				} catch (error) {
					onError(Api.asApiError(error));
				}
			};

			this.busy = true;

			this.webServiceHelper.callWebServiceWithOperationResults<Api.IAuthenticationResponse>(
				'user/login/v2',
				'POST',
				request,
				onSuccess,
				onError,
				null,
				false
			);
		});
	};

	@action
	public logout() {
		return this.setUserSession(null);
	}

	@action
	public load() {
		if (this.isLoaded) {
			return Promise.resolve(this.mApiUserSession);
		}

		this.loading = true;
		return new Promise<Api.IUserSession>((resolve, reject) => {
			const rejectWithApiError = (error?: Api.IOperationResultNoValue) => {
				runInAction(() => {
					this.loading = false;
					reject(error);
				});
			};
			this.getStoredCredential().then(
				(credential: Api.ICredential) => {
					if (credential) {
						this.getUserSessionWithCredential(credential)
							.then(userSession => {
								runInAction(() => {
									this.loading = false;
									resolve(userSession);
								});
							})
							.catch(rejectWithApiError);
					} else {
						rejectWithApiError();
					}
				},
				e => {
					rejectWithApiError(Api.asApiError(e));
				}
			);
		});
	}

	public setUserMilestone = <T>(name: keyof Api.IUserMilestones, value: T) => {
		if (this.isAuthenticated && name && value !== null && value !== undefined) {
			return new Promise((resolve, reject) => {
				const query = toQueryStringParams({ name, value });
				this.webServiceHelper.callWebServiceWithOperationResults<Api.IUserMilestones>(
					`user/${this.user.id}/updateUserMilestone?${query}`,
					'PATCH',
					null,
					opResult => {
						if (this.user) {
							this.user.userMilestones = opResult.value;
						}
						resolve(opResult.value);
					},
					reject
				);
			});
		}

		return null;
	};

	public updateUser = (user: Api.IUser) => {
		this.mApiUserSession = {
			...(this.mApiUserSession || {}),
			user,
		};
	};

	@computed
	public get configuredIntegrations() {
		return Object.keys(this.mApiUserSession?.account?.integrations || {}).filter(
			integration => this.mApiUserSession?.account?.integrations[integration as keyof Api.IAccountIntegrations]?.enabled
		) as (keyof Api.IAccountIntegrations)[];
	}

	@computed
	public get hasInsuranceIntegrationConfigured() {
		const configuredIntegrations = this.configuredIntegrations;
		return configuredIntegrations.some(integration =>
			['qqCatalyst', 'eclipse', 'ams360', 'hawkSoft', 'epicCsv', 'ezLynxCsv', 'generalCsv'].includes(integration)
		);
	}

	@computed
	public get hasNonProfitIntegrationConfigured() {
		const configuredIntegrations = this.configuredIntegrations;
		return configuredIntegrations.some(integration => ['donorPerfect'].includes(integration));
	}

	@action
	private checkAndSetTimezone = () => {
		if (this.mApiUserSession && !this.mApiUserSession?.user?.userPreferences?.timeZone) {
			const newTimeZone = moment.tz.guess();
			if (newTimeZone) {
				const onFinish = (result: Api.IOperationResult<Api.IUserPreferences>) => {
					runInAction(() => {
						if (result.success) {
							this.mApiUserSession.user.userPreferences = result.value;
						}
					});
				};
				this.webServiceHelper.callWebServiceWithOperationResults<Api.IUserPreferences>(
					this.composeApiUrl({ urlPath: 'user/preferences' }),
					'PUT',
					{
						...this.mApiUserSession.user.userPreferences,
						timeZone: newTimeZone,
					},
					onFinish,
					onFinish
				);
			}
		}
	};

	@action
	private getUserSessionWithCredential = async (credential: Api.ICredential) => {
		this.loading = true;

		const operation = await this.webServiceHelper.callWebServiceAsync<Api.IUserSession>(
			'user/context',
			'GET',
			null,
			credential
		);

		if (operation.success && operation.value) {
			const userSession = operation.value;
			return runInAction(() => {
				this.checkAndSetTimezone();
				this.setUserSession(userSession);
				this.loading = false;
				return userSession;
			});
		} else {
			runInAction(() => {
				this.loading = false;
			});
			throw operation;
		}
	};

	/** Set the user and credential, update the web service helper and flag this session as authenticated */
	protected setUserSession(userSession?: Api.IUserSession) {
		if (userSession) {
			this.mApiUserSession = userSession;
			this.mAccount = new AccountViewModel(this, userSession.account);
		} else {
			const credentialStore = this.webServiceHelper.getCredentialStore();
			credentialStore.clear();
			this.mApiUserSession = null;
			this.mAccount = null;
		}
	}

	protected async getStoredCredential() {
		const credential = await this.webServiceHelper.getCredentialStore().getCredential();

		// Basically check if there is any sort of value
		// We assume that the underlying SDK call is taking care of refresing the access token in result of a 401 (expiriation)
		if (credential && credential.access_token) {
			return credential;
		}

		return null;
	}

	@action
	private onRequestStart = () => {
		this.requestCount = this.requestCount + 1;
	};

	@action
	private onRequestEnd = () => {
		this.requestCount = this.requestCount - 1;
	};
}

export const getEmptyPageControllerResolvedPromise = <T>() => {
	return Bluebird.resolve<Api.IPageCollectionControllerFetchResult<T[]>>({
		fetchedFirstPage: false,
		values: [],
	});
};

export class ObservablePageCollectionControllerOld<
	TModel extends Api.IBaseApiModel = Api.IBaseApiModel,
	TItem extends Api.IBaseApiModel = Api.IBaseApiModel,
> {
	@observable public fetching: boolean;
	@observable.ref public fetchResults: TItem[];
	protected controller: Api.PageCollectionController<TModel>;
	protected fetchPromise: Bluebird<Api.IPageCollectionControllerFetchResult<TModel[]>>;
	protected transformer: (model: TModel) => TItem;

	constructor(
		client: Api.WebServiceHelper,
		apiPath: string,
		apiParams?: Api.IDictionary,
		transformer?: (model: TModel) => TItem
	) {
		this.controller = new Api.PageCollectionController<TModel>(client, apiPath, apiParams);
		this.transformer =
			transformer ||
			(model => {
				return model as any;
			});
	}

	public get totalCount() {
		return this.controller.getTotalCount();
	}

	@action
	public reset = () => {
		this.cancelCurrentFetch();
		this.controller.reset();
		this.fetching = false;
		this.fetchResults = null;
	};

	@action
	public getNext(sortDescriptor?: Api.IPagedResultFetchContext, pageSize?: number, params?: any) {
		if (!sortDescriptor) {
			sortDescriptor = {
				sort: 'asc',
			};
		}

		this.cancelCurrentFetch();
		if (this.controller.hasAllPages(sortDescriptor, pageSize, params)) {
			return getEmptyPageControllerResolvedPromise<TModel>();
		}

		this.fetching = true;
		const promise = this.controller.getNext(sortDescriptor, pageSize, params);

		const onFinish = () => {
			if (promise === this.fetchPromise) {
				this.fetchPromise = null;
			}
			this.fetching = false;
		};

		promise
			.then(result => {
				runInAction(() => {
					const fetchResultsDictionary: Api.IDictionary<TItem> = {};
					let fetchResults = result.values.map(x => {
						const transformed = this.transformer(x);
						if (transformed) {
							fetchResultsDictionary[transformed.id] = transformed;
						}
						return transformed;
					});

					// note: we use fetchResultsDictionary to remove items in this.fetchResults that have a matching id with an item in the transformed result.values collection
					fetchResults = result.fetchedFirstPage
						? fetchResults
						: fetchResults.length > 0
							? [...this.fetchResults.filter(x => !fetchResultsDictionary[x.id]), ...fetchResults]
							: this.fetchResults;

					this.fetchResults = fetchResults;
					onFinish();
				});
			})
			.catch(() => {
				runInAction(() => {
					this.fetchResults = [];
					onFinish();
				});
			});
		this.fetchPromise = promise;
		return promise;
	}

	@action
	public removeItems = (items: TItem[]) => {
		if (this.fetchResults) {
			const indexes = this.fetchResults
				.map(x => {
					const index = items.indexOf(x);
					if (index >= 0) {
						return index;
					}
					return null;
				})
				.filter(x => x);
			this.controller.removeItems(indexes);
			this.fetchResults = this.fetchResults.filter(x => items.indexOf(x) < 0);
		}
	};

	@action
	public insertItems = (items: Api.IPageCollectionControllerInsert<TModel>[]) => {
		this.controller.insertItems(items);
		const fetchResultsDictionary = (this.fetchResults || []).reduce<Api.IDictionary<TItem>>((result, x) => {
			if (x.id) {
				result[x.id] = x;
			}
			return result;
		}, {});

		// need to loop over the models and create transformed versions in this.fetchResults
		const fetchResults: TItem[] = [];
		(this.controller.fetchResults || []).forEach(x => {
			fetchResults.push(fetchResultsDictionary[x.id] || this.transformer(x));
		});
		this.fetchResults = fetchResults;
	};

	@action
	public cancelCurrentFetch() {
		if (this.fetchPromise) {
			this.fetchPromise.cancel();
			this.fetchPromise = null;
		}
		this.fetching = false;
	}
}
