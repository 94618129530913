import { BaseResourceModel, FilterOperator, FilterRequestBase, IBaseApiTypeModel, UserReference } from './index';

export enum AIConversationType {
	Default = 'Default',
	EmailContentGeneration = 'EmailContentGeneration',
	SocialMediaContentGeneration = 'SocialMediaContentGeneration',
	Translation = 'Translation',
	GoogleReviewReply = 'GoogleReviewReply',
	MediaKeywordGeneration = 'MediaKeywordGeneration',
	BlogGeneration = 'BlogGeneration',
	ContactClassification = 'ContactClassification',
	CopyMage = 'CopyMage',
}

export enum AIChatRole {
	Assistant = 'Assistant',
	System = 'System',
	User = 'User',
}

export interface AIConversation extends BaseResourceModel {
	participants: AIConversationParticipant[];
	conversationType?: AIConversationType;
}

interface AIConversationParticipant {
	id: string;
}

export interface AIUserParticipant extends AIConversationParticipant {
	userId?: string;
	reference?: UserReference;
}

export interface AIAssistantParticipant extends AIConversationParticipant {
	assistantName?: string;
}

type AIChatContent = IBaseApiTypeModel;

export interface AIChatText extends AIChatContent {
	text: string;
}

export interface AIChatMessage extends BaseResourceModel {
	participantId?: string;
	aiConversationId?: string;
	role?: AIChatRole;
	contents?: AIChatContent[];
	text?: string;
}

export interface AIChatRequest {
	chatMessages?: AIChatMessage[];
}

export enum AIConversationFilterProperty {
	ConversationType,
}

export interface AIConversationFilterCriteria {
	criteria: AIConversationFilterCriteria[];
	op?: FilterOperator;
	property: AIConversationFilterProperty;
	value?: string;
	values?: Record<string, string>;
}

export type AIConversationFilter = FilterRequestBase<AIConversationFilterProperty, AIConversationFilterProperty>;

export interface AIConversationStarter {
	title: string;
	subTitle?: string;
	chatMessages: AIChatMessage[];
}
