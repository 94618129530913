import * as StompJs from '@stomp/stompjs';
import { computed, observable } from 'mobx';
import { RemoteEventsViewModel } from './RemoteEventsViewModel';
import * as Api from './sdk';
import { UserSessionContext } from './viewModels/index';

export class RemoteResourceEventsViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
	TResource extends Api.IBaseApiModel | string = Api.IBaseApiModel | string,
	TValue = any,
> {
	protected mDeliveryOptions: Api.IRemoteEventsDeliveryOptions;
	protected mEventType: Api.RemoteEventType;

	protected mOnMessageCallback: (event: Api.IRemoteEvent<TValue> | Api.IRemoteEvent<TValue>[]) => void;
	protected mRemoteEvents: RemoteEventsViewModel;
	protected mResource: TResource;

	protected mSubscription: { id: string; dispose: () => void };
	@observable.ref protected mUserSession: TUserSession;

	constructor(
		userSession: TUserSession,
		resource: TResource,
		eventType: Api.RemoteEventType = '#',
		eventLogger?: Api.IEventLoggingService,
		deliveryOptions: Api.IRemoteEventsDeliveryOptions = {
			delay: 1,
			type: Api.RemoteResourceDeliveryOptionType.ALL_SINCE_LAST_DELIVERY,
		}
	) {
		this.mUserSession = userSession;
		this.mRemoteEvents = new RemoteEventsViewModel(userSession, eventLogger);
		this.mResource = resource;
		this.mEventType = eventType;
		this.mDeliveryOptions = deliveryOptions;
		this.composeRoute = this.composeRoute.bind(this);
		this.onMessage = this.onMessage.bind(this);
		this.onConnect = this.onConnect.bind(this);
		this.permananentlyDisconnect = this.permananentlyDisconnect.bind(this);
	}

	@computed
	public get connectionStatus() {
		return this.mRemoteEvents.connectionStatus;
	}

	public connect = (stompConfig?: StompJs.StompConfig) => {
		const promise = this.mRemoteEvents.connect(stompConfig);
		promise?.then(this.onConnect);
		return promise;
	};

	public permananentlyDisconnect() {
		return this.mRemoteEvents.permanentlyDisconnect();
	}

	protected composeRoute() {
		const resourceType =
			(typeof this.mResource === 'string' ? this.mResource : (this.mResource as Api.IBaseApiModel)?._type) || '';
		const id = (typeof this.mResource === 'string' ? '' : (this.mResource as Api.IBaseApiModel)?.id) || '';
		return `${resourceType}${id ? `.${id}` : ''}.${this.mEventType || '#'}`;
	}

	protected onMessage(event: Api.IRemoteEvent<TValue> | Api.IRemoteEvent<TValue>[]) {
		if (this.mOnMessageCallback) {
			this.mOnMessageCallback(event);
		}
	}

	protected onConnect() {
		this.mSubscription = this.mRemoteEvents.addEventHandler(this.composeRoute(), this.onMessage, this.mDeliveryOptions);
	}
}
