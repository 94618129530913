import { css } from 'aphrodite';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { ISelectBoxOption, SelectBox } from '../../SelectBox';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

export interface INameValue {
	name: string;
	value: string;
}

interface IProps {
	className?: string;
	onSelectionChanged?(selected: INameValue): void;
	options: INameValue[];
	selectedValue?: string;
}

interface IState {
	inputValue?: string;
	lastModifiedInput?: number;
	selectedOption?: ISelectBoxOption<INameValue>;
}

class _ValueSelector extends React.PureComponent<IProps, IState> {
	@observable.ref private selectOptions: ISelectBoxOption<INameValue>[];
	public readonly state: IState = {
		inputValue: '',
	};

	public UNSAFE_componentWillMount() {
		if (!this.selectOptions) {
			this.generateOptions();
		}

		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	private generateOptions = () => {
		const { options } = this.props;

		this.selectOptions = options.map(x => {
			const option: ISelectBoxOption<INameValue> = {
				id: uuidgen(),
				title: x.name,
				value: x,
			};
			return option;
		});
		return this.selectOptions;
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className } = this.props;
		const { selectedOption, inputValue } = this.state;
		return (
			<SelectBox
				className={`${css(styleSheet.container)} value-selector ${className || ''}`}
				leftAccessory={!selectedOption ? <SearchIcon className={css(styleSheet.icon)} /> : null}
				menuClassName={css(styleSheet.selectMenu)}
				onOpenChanged={this.onOpenChanged}
				onSelectionChanged={this.onSelectionChanged}
				options={this.selectOptions}
				searchValue={inputValue}
				selectedOption={selectedOption}
				triggerClassName={css(styleSheet.selectTrigger)}
			/>
		);
	}

	private onOpenChanged = (isOpen: boolean) => {
		this.resetFilter();
		document?.removeEventListener('keydown', this.onInputChange);
		if (isOpen) {
			document?.addEventListener('keydown', this.onInputChange);
		}
	};

	private onInputChange = (e: KeyboardEvent) => {
		const { lastModifiedInput, inputValue } = this.state;

		let newInputValue: string = Date.now() - lastModifiedInput > 3000 ? '' : inputValue.concat(e.key);
		if (e.key === ' ') {
			e.preventDefault();
		} else if (e.key === 'Backspace') {
			newInputValue = inputValue.substring(0, inputValue.length - 1);
		} else if (e.key === 'Enter') {
			const match = this.selectOptions.find(
				x => x.title.toString().toLocaleLowerCase() === inputValue.toLocaleLowerCase()
			);
			if (match !== undefined) {
				this.setState({ inputValue: '', selectedOption: match });
			}
			return;
		} else if (e.key.length > 1) {
			return;
		}

		this.setState(
			{
				inputValue: newInputValue,
				lastModifiedInput: Date.now(),

				selectedOption: null,
			},
			() => {
				const possibleOptions = this.selectOptions.filter(x =>
					x.title.toString().toLocaleLowerCase().includes(this.state.inputValue.toLocaleLowerCase())
				);
				this.selectOptions = this.state.inputValue && possibleOptions.length ? possibleOptions : this.generateOptions();
			}
		);
	};

	private resetFilter = () => {
		this.setState({ inputValue: '', lastModifiedInput: Date.now() });
		this.generateOptions();
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};

		if (!nextProps.selectedValue && !!this.state.selectedOption) {
			nextState.selectedOption = null;
		} else if (nextProps.selectedValue) {
			const defaultSelectedOption: ISelectBoxOption<INameValue> = {
				title: nextProps.selectedValue,
				value: {
					name: nextProps.selectedValue,
					value: nextProps.selectedValue,
				},
			};

			const option = this.selectOptions.find(x => x.value.value === nextProps.selectedValue) || defaultSelectedOption;
			if (this.state.selectedOption !== option) {
				nextState.selectedOption = option;
			}
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	private onSelectionChanged = (selectedOption: ISelectBoxOption<INameValue>) => {
		const { onSelectionChanged } = this.props;
		if (onSelectionChanged) {
			onSelectionChanged(selectedOption.value);
		} else {
			this.setState({
				selectedOption,
			});
		}
	};
}

export const ValueSelector = observer(_ValueSelector);
