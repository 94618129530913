import * as Api from '@ViewModels';
import Bugsnag, { Client, Event, NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';
import { IBuildInfo } from '.';
import BuildInfo from '../web/assets/build-info.json';
import { AppState } from './AppState';
import { IEnvironment } from './Environment';

interface IHighlightLogger {
	getSessionDetails(): Promise<{
		url: string;
		urlWithTimestamp: string;
	}>;
}

declare const H: IHighlightLogger;

export const EventCategories = {
	Error: 'error',
	PageView: 'pageView',
};

export type EventInputType = 'Click' | 'Direct';

const RedactedQueryStringKeys = [/^code$/i, /^iut$/i, /^query$/i, /^state$/i, /^wut$/i];

export class BugsnagEventLogger implements Api.ILogger<Api.IEventArgs> {
	private client: Client;

	constructor(
		releaseStage: 'development' | 'staging' | 'production' | 'test' = 'production',
		environment: IEnvironment = { appType: 'web' }
	) {
		const buildInfo = BuildInfo as IBuildInfo;
		this.client = Bugsnag.start({
			apiKey: 'bc79cb68a917c75637a9845e7f24effe',
			appType: environment.appType,
			appVersion: `${buildInfo.packageVersion}-${releaseStage} (${buildInfo.hash})`,
			// remove "log" so nrowser breakpoints point to the right line
			enabledBreadcrumbTypes:
				['development', 'test'].indexOf(releaseStage) >= 0
					? ['navigation', 'request', 'process', 'user', 'state', 'error', 'manual']
					: undefined,
			metadata: { environment },
			onError: this.onError,
			plugins: [new BugsnagPluginReact()],
			redactedKeys: RedactedQueryStringKeys,
			releaseStage,
		});
	}

	public getClient = () => {
		return this.client;
	};

	public flush = () => {
		return Promise.resolve<void>(null);
	};

	public getLogFormatter = () => {
		return null as Api.ILogFormatter<Api.ILogEvent>;
	};

	public getName = () => {
		return 'Bugsnag Event Logger';
	};

	public log = (eventArgs: Api.ILogEvent) => {
		if (eventArgs.category === EventCategories.PageView) {
			return Promise.resolve(null);
		}

		return new Promise<void>(resolve => {
			let metaData: any = {};
			if (eventArgs.label) {
				metaData.label = eventArgs.label;
			}

			const isErrorAction = eventArgs.action?.toLowerCase()?.indexOf('error') > -1;
			if (eventArgs.category === EventCategories.Error || isErrorAction) {
				let errorMessage = `${eventArgs.category}: ${eventArgs.action}`;
				if (eventArgs?.context?.systemMessage) {
					errorMessage = eventArgs.context.systemMessage;
				}

				// errorClass is used for grouping similar errors
				const errorClass = isErrorAction ? eventArgs.action : eventArgs.action + '-Error';

				const error: NotifiableError = {
					message: eventArgs.category === 'Crash' ? eventArgs.context.stack : errorMessage,
					name: errorClass,
				};

				const onError = (event: Event) => {
					/**
					 * Remove all logging stackframes from the error report to eliminate noise. The stacktraces on these are
					 * pretty garbage (empty) because it is not a true Javascript "Error" but, leaving it in for the sake of
					 * posterity at this point
					 */
					event.errors?.forEach(err => {
						// return anything outside of the logging infrastructure

						const index = err.stacktrace?.findIndex(frame => frame?.method?.indexOf('WithEventLogging') > -1);
						if (index >= 0) {
							err.stacktrace.splice(0, index + 1);
						}

						// return anything outside of the logging infrastructure

						const boundary = err.stacktrace?.findIndex(frame => frame?.method?.indexOf('ErrorBoundary') > -1);
						if (boundary >= 0) {
							err.stacktrace.splice(0, index + 1);
						}
					});
					/**
					 * The grouping hash will group errors by name in this case. That way, it will group all errors like
					 * "SearchCampaign-Error" together
					 */
					event.groupingHash = errorClass;
					/**
					 * Set severity to "warning" for API error because it is out of scope/control of this app The severity will be
					 * "error" if it is an unhandled error that makes the app crash
					 */
					event.severity = 'warning';
					/** EventArgs.context will provide the OperationResult from the network call */

					event.addMetadata('operation result', eventArgs.context);
				};
				this.client.notify(error, onError);
			} else {
				metaData = {
					...metaData,
					...(eventArgs.context || {}),
				};
				this.client.leaveBreadcrumb(`${eventArgs.category}: ${eventArgs.action}`, metaData);
			}

			resolve(null);
		});
	};

	public getErrorBoundary = (): React.ComponentType => {
		return class ErrorBoundary extends React.PureComponent {
			private bugsnagErrorBoundary: React.ComponentType<any>;
			public UNSAFE_componentWillMount() {
				const errorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
				this.bugsnagErrorBoundary = errorBoundary;
			}

			public render() {
				const BSErrorBoundary = this.bugsnagErrorBoundary;
				return <BSErrorBoundary>{this.props.children}</BSErrorBoundary>;
			}
		};
	};

	private onError = async (event: Event) => {
		/**
		 * Useless error from tinyMCE, but don't waste bugsnag events with it
		 * https://github.com/Real-Magic/Levitate/issues/2146
		 */
		if (
			/The component must be in a context to send: triggerEvent[\s\S]*is not in context./.test(
				event?.originalError?.message
			)
		) {
			return false;
		}

		event.breadcrumbs.forEach(x => {
			const breadcrumb: { name: string; metaData?: any } = x as any;
			if (breadcrumb?.name?.toLocaleLowerCase() === 'hash changed') {
				breadcrumb.metaData.state = null;
			}
		});

		// add user info
		const userSession = AppState.userSession;
		if (!!userSession?.isAuthenticated && !!userSession?.user) {
			const { user } = userSession;
			event.setUser(user.id, userSession.account?.id);
		}

		try {
			if (H) {
				const sessionDetails = await H.getSessionDetails();
				if (!!sessionDetails && !!sessionDetails.urlWithTimestamp) {
					event.addMetadata('highlight', {
						urlAtTime: sessionDetails.urlWithTimestamp,
					});
				}
			}
		} catch {
			// We can swallow this error, there is no way to recover from here.
		}
	};
}

class WebAppEventLogger implements Api.IEventLoggingService {
	/*
	- Category: A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
	- Action: A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
	- Label: More precise labelling of the related action. E.g. alongside the 'Added a component' action,
	we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
	- This method name is depended on to ensure cleaner stacktraces in BugSnag, if renamed update BugSnagEventLogger
	*/
	public logEvent = (args: Api.IEventArgs, context?: Api.LogEventContextType) => {
		Api.EventLogger.logEvent(args, context);
	};

	public logInput = (category: string, target: string, type: EventInputType, context?: Api.LogEventContextType) => {
		this.logEvent(
			{
				action: `${target}: ${type}`,
				category,
			},
			context
		);
	};

	public logPageView = (path: string) => {
		this.logEvent({
			action: path,
			category: EventCategories.PageView,
		});
	};

	public addLogger = (logger: Api.ILogger<Api.ILogEvent>) => {
		Api.EventLogger.addLogger(logger);
	};

	public removeLogger = (logger: Api.ILogger<Api.ILogEvent>) => {
		Api.EventLogger.removeLogger(logger);
	};
}

export const EventLogger = new WebAppEventLogger();

export interface IEventLoggingComponentProps {
	logError?(action: string, error: Error): void;
	logApiError?(action: string, error?: Api.IOperationResultNoValue): void;
	logEvent?(action: string, context?: Api.LogEventContextType): void;
	logEventWithLabel?(action: string, label: string, context?: Api.LogEventContextType): void;
	loggingCategory?: string;
	logInput?(target: string, type: EventInputType, context?: Api.LogEventContextType): void;
	logPageView?(path: string): void;
	logPromise?<TPromiseResult = any, TPromise extends Promise<TPromiseResult> = Promise<TPromiseResult>>(
		action: string,
		promise?: TPromise,
		context?: Api.LogEventContextType
	): TPromise;
}

/** @param defaultCategory Specifies the logging category if not supplied through props. */
export const withEventLogging = <P extends object = any>(
	Component: React.ComponentType<P>,
	defaultCategory?: string
): React.ComponentType<P & IEventLoggingComponentProps> => {
	return class WithEventLogging extends React.Component<P & IEventLoggingComponentProps> {
		public render() {
			const { loggingCategory, ...restProps } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			const Comp = Component as React.ComponentType<any>;
			return (
				<Comp
					{...restProps}
					logApiError={this.logApiError}
					logEvent={this.logEvent}
					logEventWithLabel={this.logEventWithLabel}
					loggingCategory={cat}
					logInput={this.logInput}
					logPageView={this.logPageView}
					logPromise={this.logPromise}
					logError={this.logError}
				/>
			);
		}

		private logApiError = (action: string, error?: Api.IOperationResultNoValue) => {
			const { loggingCategory } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			const context = error ? { ...error } : undefined;
			EventLogger.logEvent(
				{
					action,
					category: cat,
				},
				context
			);
		};

		private logError = (action: string, error: Error) => {
			const { loggingCategory } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			const context = error ? error : undefined;
			EventLogger.logEvent(
				{
					action,
					category: cat,
				},
				context
			);
		};

		private logEvent = (action: string, context?: Api.LogEventContextType) => {
			const { loggingCategory } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			EventLogger.logEvent(
				{
					action,
					category: cat,
				},
				context
			);
		};

		private logEventWithLabel = (action: string, label: string, context?: Api.LogEventContextType) => {
			const { loggingCategory } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			EventLogger.logEvent(
				{
					action,
					category: cat,
					label,
				},
				context
			);
		};

		private logInput = (target: string, type: EventInputType, context?: Api.LogEventContextType) => {
			const { loggingCategory } = this.props;
			const cat = loggingCategory || defaultCategory || '';
			EventLogger.logInput(cat, target, type, context);
		};

		private logPageView = (path: string) => {
			EventLogger.logPageView(path);
		};

		private logPromise = <TPromise extends Promise<any> = Promise<any>>(
			action: string,
			promise?: TPromise,
			context?: Api.LogEventContextType
		) => {
			if (!!promise && !!action) {
				const { loggingCategory } = this.props;
				const cat = loggingCategory || defaultCategory || '';
				EventLogger.logEvent(
					{
						action,
						category: cat,
					},
					context
				);

				promise.catch(e => {
					const error = Api.asApiError(e);
					const actionWithErrorSuffix = `${action.replace(/-error$/i, '')}-Error`;
					this.logApiError(actionWithErrorSuffix, error);
				});
			}

			return promise;
		};
	};
};

export interface ILogger {
	logApiError: (action: string, error?: Api.IOperationResultNoValue) => void;
	logEvent: (action: string, context?: Api.LogEventContextType) => void;
	logEventWithLabel: (action: string, label: string, context?: Api.LogEventContextType) => void;
	logInput: (target: string, type: EventInputType, context?: Api.LogEventContextType) => void;
	logPageView: (path: string) => void;
	logPromise: <TPromise extends Promise<any> = Promise<any>>(
		action: string,
		promise?: TPromise,
		context?: Api.LogEventContextType
	) => TPromise;
}

export const useEventLogging = (loggingCategory = '') => {
	const logApiError = React.useCallback((action: string, error?: Api.IOperationResultNoValue) => {
		const context = error ? { ...error } : undefined;
		EventLogger.logEvent(
			{
				action,
				category: loggingCategory,
			},
			context
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logEvent = React.useCallback((action: string, context?: Api.LogEventContextType) => {
		EventLogger.logEvent(
			{
				action,
				category: loggingCategory,
			},
			context
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logEventWithLabel = React.useCallback((action: string, label: string, context?: Api.LogEventContextType) => {
		EventLogger.logEvent(
			{
				action,
				category: loggingCategory,
				label,
			},
			context
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logInput = React.useCallback((target: string, type: EventInputType, context?: Api.LogEventContextType) => {
		EventLogger.logInput(loggingCategory, target, type, context);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logPageView = React.useCallback((path: string) => {
		EventLogger.logPageView(path);
	}, []);

	const logPromise = React.useCallback(
		<TPromise extends Promise<any> = Promise<any>>(
			action: string,
			promise?: TPromise,
			context?: Api.LogEventContextType
		) => {
			if (!!promise && !!action) {
				EventLogger.logEvent(
					{
						action,
						category: loggingCategory,
					},
					context
				);

				promise.catch(e => {
					const error = Api.asApiError(e);
					const actionWithErrorSuffix = `${action.replace(/-error$/i, '')}-Error`;
					logApiError(actionWithErrorSuffix, error);
				});
			}

			return promise;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return React.useRef<ILogger>({
		logApiError,
		logEvent,
		logEventWithLabel,
		logInput,
		logPageView,

		logPromise,
	}).current;
};
